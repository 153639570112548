<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div v-if="this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5" class="col-md-1"
         style="position: absolute;right: 56%;top: 75px;">

      <label class="form-label" for="formrow-voucherType-input" style="font-size: 12px;">Select Country </label>
      <multiselect v-model="country" :options="countryArr" :show-labels="false" label="country"
                   track-by="country" @input="getCorporateCities();"></multiselect>
    </div>
    <div v-if="this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5" class="col-md-2"
         style="position: absolute;right: 39.5%;top: 75px;">

      <label style="font-size: 12px;">Select City </label>
      <multiselect v-model="city" :options="cityArr" :show-labels="false" label="city"
                   track-by="city" @input="getCorporateBranchByCorpID();"></multiselect>
    </div>

    <div v-if="this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5" class="col-md-2"
         style="position: absolute;right: 23%;top: 75px;">

      <label style="font-size: 13px;">Select Corporate Branch* </label>
      <multiselect v-model="branchID" :options="branches" :show-labels="false" label="corpBranchName"
                   track-by="corpBranchID" @input="onchangeBranch();"></multiselect>
    </div>
    <div class="col-sm-6 col-md-3" style="position: absolute;right: 5%;top: 75px;width: 18%;">
      <label class="form-label" for="formrow-date-input" style="font-size: 13px;">Date</label>
      <date-picker
          v-model="dateFilter"
          append-to-body
          confirm
          format="DD MMM Y"
          value-type="format"
          lang="en"
          range
      ></date-picker>
    </div>

    <div class="col-md-12" style="width: auto; margin-top: auto; float: right;">
      <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
        Apply Filter
      </button>
      &nbsp;
      <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
        Clear Filter
      </button>
    </div>
    <div class="row" style="margin-top: 30px;">
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="mb-1 mt-1">
                {{ foodcourtCardData.getTotalRest }}
                <!-- <span data-plugin="counterup">
                    <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                </span> -->
              </h4>
              <p class="text-muted mb-0">Total Restaurants</p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="mb-1 mt-1">
                       
                        <span data-plugin="counterup">
                            <countTo :duration="2000" :endVal="foodcourtCardData.getTotalOrders ? foodcourtCardData.getTotalOrders:0 "
                                     :startVal="0"></countTo>
                        </span>
              </h4>
              <p class="text-muted mb-0">Total Orders</p>
            </div>

          </div>
        </div>
      </div>


    </div>


    <div class="row">

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                <div class="btn-group" role="group">
                  <button v-if="this.$storageData.login_type == 1" class="btn btn-themeBrown" type="button">Excel
                  </button>
                  <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select v-model="perPage"
                                   :options="pageOptions"
                                   size="sm"
                                   style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        class="form-control form-control-sm ms-2"
                        placeholder="Search..."
                        type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- <div class="row">

                 <div class="col-sm-6 col-md-3">
                    <label class="form-label" for="formrow-date-input">Date</label>
                      <date-picker v-model="dateFilter" format="DD MMM Y" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>
                </div>
                <div class="col-sm-6 col-md-2 mt-3">
                    <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear Filter </button>
                </div>
        </div> -->
            <!-- Table -->
            <div class="table-responsive mb-0">

              <b-table
                  :current-page="currentPage" :fields="fields"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :items="tableData"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  bordered
                  hover
                  outlined
                  responsive
                  striped
                  @filtered="onFiltered"

              >

                <template v-slot:cell(transactionType)="data">
                  <span v-if="data.item.isExpired==1" style="color:#F3766A;"> Expired </span>
                  <span v-else-if="data.item.transactionType==1" style="color:green;"> Credit </span>
                  <span v-else-if="data.item.transactionType==2" style="color:#A4827D;"> Debit </span>

                </template>

                <template v-slot:cell(amount)="data">
                  <span v-if="data.item.amount">₹{{ data.item.amount }} </span>

                </template>


              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :per-page="perPage"
                        :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Food Court Statistics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {Layout, PageHeader, countTo, DatePicker, Multiselect},
  data() {
    return {
      tableData: [],
      foodcourtCardData: [],
      templateArr: [],
      restBranchArr: [],
      restBranchID: "",
      branches: [],
      branchID: "",
      cityArr: [],
      city: "",
      country: "",
      countryArr: [],
      dateFilter: [],
      title: "Food Court Statistics",
      items: [
        {
          text: "View",
        },
        {
          text: "Food Court Statistics",
          active: true,
        },
      ],
      corpBranchID: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "totalOrders",
          sortable: true,
        },
      ],
    };
  },
  created() {
    // this.corpBranchID = this.$route.params.id;
    //     if(this.corpBranchID){
    //         sessionStorage.setItem('corpBranchID',this.corpBranchID);// Save to sessionStorage
    //     }else{
    //        this.corpBranchID =  sessionStorage.getItem('corpBranchID');
    //     }

    this.corpBranchID = (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) ? this.brandLogin() : this.getStats();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    //this.getCorporateBranchByCorpID();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    brandLogin() {
      this.getCorporateCountry();
    },
    getCorporateCountry() {
      this.axios
          .post(
              this.$loggedRole+"/getCorporateCountry", {corporateID: this.$storageData.profile.corporateID}
          )
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = (this.countryArr) ? this.countryArr[0] : "";
            this.getCorporateCities();

          });
    },
    getCorporateCities() {
      this.axios
          .post(this.$loggedRole+
              "/getCorporateCities", {
                corporateID: this.$storageData.profile.corporateID,
                country: (this.country) ? this.country.country : "",
               corpBranchID:this.$storageData.profile.empTypeID==6 ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = (this.cityArr) ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();
            // this.readfoodCourtStats();
          });
    },
    getCorporateBranchByCorpID() {
      this.axios.post(this.$loggedRole+"/getCorporateBranchByCorpID", {
                corporateID: this.$storageData.profile.corporateID,
                city: (this.city) ? this.city.city : "",
            corpBranchID:this.$storageData.profile.corpBranchID ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.branches = response.data.data;
            // this.branchID = this.branches[0];
            // this.corpBranchID = this.branchID.corpBranchID;
            // this.readfoodCourtStats();

          });
    },
    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
      // this.readfoodCourtStats();
    },
    getStats() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      // this.readfoodCourtStats();
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readfoodCourtStats() {
      this.axios
          .post(this.$loggedRole+
              "/getFoodCourtStatistics",
              {
                'corpBranchID': this.corpBranchID ? this.corpBranchID : this.$storageData.profile.corpBranchID,
                'transactionType': (this.typeID) ? this.typeID.typeID : 0,
                'dateFilter': this.dateFilter,
              }
          )
          .then((response) => {
            //Then injecting the result to datatable parameters.

            this.tableData = response.data.data;
            this.foodcourtCardData = response.data.cardData;
            //console.log((response));

          });
    },
    applyFilter() {
      this.corpBranchID = (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) ? this.branchID.corpBranchID : this.$storageData.profile.corpBranchID;
      this.readfoodCourtStats();

    },
    clearFilter() {
      this.typeID = 0;
      this.dateFilter = [];
      this.bra
      this.corpBranchID = this.branchID = "";
      this.readfoodCourtStats();
    }
  },
  middleware: "authentication",
};
</script>
<style scoped>

.my-class {
  max-width: 10px !important;
}
</style>